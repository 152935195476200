import {
  Account,
  BetSlip,
  BetSlipItem,
  BetSlipItemType,
  BetSlipSource,
  BetSlipState,
  BetSlipSystem,
  BranchBetSettingOptions,
  BranchBetSettings,
  CheckBetResult,
  CheckBetSlipItemError,
  CheckBetSlipItemStatus,
  CheckBetSlipOptions,
  CheckBetSlipResult,
  CheckBetSlipResultType,
  Game,
  Market,
  Odd,
  OddState,
  PlaceBetSlipResult,
  PlaceBetSlipResultType,
  SubmitBetSlipResult,
  SubmitResultState,
  TestNewBetSlipItemResult,
  TokenBonusCheckOptions,
  User,
  UserType,
} from '@arland-bmnext/api-data'
import { BehaviorSubject, map, Observable, Subject, Subscription } from 'rxjs'
import {
  checkBet,
  checkBets,
  checkBetsWithBonuses,
  getBetState,
  placeBets,
  shareBet,
  testNewBetItem,
} from '../../lib/bets'
import { isLoggedInCustomer } from '../../lib/user'
import {
  BetKey,
  BetKeyType,
  BetServiceChangedOdd,
  BetSlipCalculateRequest,
  BetSlipInfo,
  BetSlipServiceBet,
  BetSlipServiceBetSlipItem,
  BetSlipServiceResult,
  BetSlipServiceStateBet,
  BetSlipServiceStateBetState,
  BetSlipShareRequest,
  BetSlipSubmitRequest,
  BetTypes,
  BetValue,
  ModifyBetSlipResult,
  ModifyBetSlipResultStatus,
  StakeMode,
} from './bet.models'

const checkOptionsAnonymous =
  CheckBetSlipOptions.GameStates | CheckBetSlipOptions.GameStartDates | CheckBetSlipOptions.OddStates
// CheckBetSlipOptions.OddValueChanges |
// CheckBetSlipOptions.Limits |
// CheckBetSlipOptions.Stake |
// CheckBetSlipOptions.MaximumGainRules |
// CheckBetSlipOptions.CustomCheck

const checkOptionsLoggedOn = CheckBetSlipOptions.All

export class BetSlipService {
  public static oddOfSameGameSelectedEvent: Subject<void> = new Subject<void>()
  private _betslip: BehaviorSubject<BetSlipServiceResult> = new BehaviorSubject<BetSlipServiceResult>(null)
  public readonly betslip: Observable<BetSlipServiceResult> = this._betslip.asObservable()
  private _betCalculationInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
  public readonly betCalculationInProgress: Observable<boolean> = this._betCalculationInProgress.asObservable()
  private _betslipInfo: BetSlipInfo = null
  private branchBetSettings: BranchBetSettings = new BranchBetSettings()
  private stake: number = 0
  private stakeMode: StakeMode = 'totalStake'
  private givenTokenBonusId: number = null
  private oddSelectionChangeEvent = new Subject<any>()
  private oddSelectionChangeEventSubscription: Subscription = null

  constructor() {}

  public init() {
    this.oddSelectionChangeEventSubscription = this.oddSelectionChangeEvent
      .pipe(
        // debounceTime(1000),
        map(async (params: any) => {
          await this.createBets(params.calculate, params.betTypes)
          this.updateResult(params.addModificationResult)
        }),
      )
      .subscribe()
  }

  public cleanup() {
    this.oddSelectionChangeEventSubscription.unsubscribe()
  }

  public setUserData(
    branchBetSettings: BranchBetSettings,
    source: BetSlipSource,
    user: User,
    accounts: Account[],
    stake: number,
  ) {
    this.branchBetSettings = branchBetSettings
    this.stake = stake

    const info = new BetSlipInfo()
    info.source = source
    info.userId = user.id
    info.userType = user.type
    info.branchId = user.branchId
    info.accountId = user.mainAccount?.id
    info.mainAccountId = user.mainAccount?.id
    info.accounts = accounts
    info.currencyId = isLoggedInCustomer(user)
      ? accounts?.find((a) => a?.id == info.accountId)?.currencyId
      : user?.branch?.defaultCurrencyId

    this._betslipInfo = info
    this._betslip.next(this.betSlipInfoToResult())
  }

  public changeStake(stake: number, stakeMode: StakeMode) {
    this.stake = stake
    this.stakeMode = stakeMode
  }

  public changeAccount(accountId: number) {
    this._betslipInfo.accountId = accountId
  }

  public clearBetSlip() {
    const info = this._betslipInfo

    if (info?.isSubmitted()) {
      return this.updateResult()
    }

    this.givenTokenBonusId = null
    this.clearBetInt(info)
    this.updateResult()
  }

  public reset() {
    const info = this._betslipInfo
    info.submitted = null
    info.submittedGuid = null
    info.waitSeconds = null
    this.clearBetInt(info)
    this.updateResult()
  }

  public async getCurrentState(): Promise<void> {
    const info = this._betslipInfo

    if (!info.isSubmitted()) {
      return this.updateResult(true)
    }

    const submitResult = await getBetState(info.submittedGuid)

    if (submitResult.state == SubmitResultState.Processed) {
      info.submitted = null
      info.submittedGuid = null
      info.waitSeconds = null

      for (let i = 0; i < info.submittedItems.length; i++) {
        info.submittedItems[i].placeBetResult = submitResult.results[i] as PlaceBetSlipResult
        if (info.submittedItems[i].placeBetResult.placeResultType == PlaceBetSlipResultType.Ok) {
          info.submittedItems[i].state = BetSlipServiceStateBetState.Booked
        }
      }

      info.submitted = null
      info.submittedGuid = null
      info.waitSeconds = null
      info.submittedItems = null
    } else if (submitResult.state == SubmitResultState.Error) {
      info.submittedItems.forEach((si) => {
        si.state = BetSlipServiceStateBetState.Error
      })

      info.submitted = null
      info.submittedGuid = null
      info.waitSeconds = null
      info.submittedItems = null
    }

    return this.updateResult(true)
  }

  public async updateBetSlipItem(market: Market, odd: Odd, betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo

    if (info == undefined) {
      return
    }

    info.itemMap[odd.id].odd = odd
    info.itemMap[odd.id].oddValue = odd.value
    info.itemMap[odd.id].market = market

    await this.createBets(true, betTypes)
    this.updateResult(false)
  }

  public async addOdd(
    game: Game,
    market: Market = null,
    odd: Odd,
    calculate: boolean = true,
    betTypes: BetTypes = null,
  ): Promise<void> {
    const info = this._betslipInfo

    if (info == undefined) {
      return
    }

    if (info.isSubmitted()) {
      this.updateResult()
      return
    }

    if (
      info.createdBets != null &&
      info.createdBets.some((bs) => bs.value.state == BetSlipServiceStateBetState.Booked)
    ) {
      this.clearBetInt(info)
    }

    // check if odd already on slip
    if (info.itemMap[odd.id] != undefined) {
      this.updateResult()
      return
    }

    // check state of game and odd
    if (odd.state != OddState.Open) {
      this.updateResultError(game.id, odd.id, ModifyBetSlipResultStatus.OddNotOpen)
      return
    }

    // create new betslip item
    const item = new BetSlipServiceBetSlipItem(game, odd, market)

    const previousItemWithSameGame = info.items?.find((item) => item.gameId === game.id && item.oddId !== odd.id)
    if (previousItemWithSameGame != undefined) {
      info.items.splice(info.items.indexOf(previousItemWithSameGame), 1)
      info.itemMap[previousItemWithSameGame.oddId] = undefined

      BetSlipService.oddOfSameGameSelectedEvent.next()
    }

    // const result = await this.testNewBetItem(item)

    // if (result.type != TestNewBetSlipItemResultType.Ok) {
    //   this.updateResultError(game.id, odd.id, ModifyBetSlipResultStatus.TestNewBetItemError, result)
    //   return
    // }

    info.items.push(item)
    info.itemMap[odd.id] = item

    this.updateResult()

    this.oddSelectionChangeEvent.next({ calculate, betTypes, addModificationResult: true })
  }

  public async addBetBuilderOdd(
    game: Game,
    market: Market,
    odd: Odd,
    calculate: boolean = true,
    betTypes: BetTypes = null,
  ): Promise<void> {
    const info = this._betslipInfo

    if (info == undefined) {
      return
    }

    if (info.isSubmitted()) {
      this.updateResult()
      return
    }

    if (
      info.createdBets != null &&
      info.createdBets.some((bs) => bs.value.state == BetSlipServiceStateBetState.Booked)
    ) {
      this.clearBetInt(info)
    }

    // check if odd already on slip
    if (info.itemMap[odd.id] != undefined) {
      this.updateResult()
      return
    }

    // check state of game and odd
    if (odd.state != OddState.Open) {
      this.updateResultError(game.id, odd.id, ModifyBetSlipResultStatus.OddNotOpen)
      return
    }

    const item = new BetSlipServiceBetSlipItem(game, odd, market)

    const previousItemWithSameGame = info.items?.find((item) => item.gameId === game.id && item.oddId !== odd.id)
    if (previousItemWithSameGame != undefined) {
      info.items.splice(info.items.indexOf(previousItemWithSameGame), 1)
      info.itemMap[previousItemWithSameGame.oddId] = undefined

      BetSlipService.oddOfSameGameSelectedEvent.next()
    }

    info.items.push(item)
    info.itemMap[odd.id] = item

    this.updateResult()

    this.oddSelectionChangeEvent.next({ calculate, betTypes, addModificationResult: true })
  }

  public async removeOdd(oddId: number, calculate: boolean = true, betTypes: BetTypes = null) {
    const info = this._betslipInfo

    if (info.isSubmitted()) {
      return this.updateResult()
    }

    const itemToRemove = info.itemMap[oddId]
    if (itemToRemove == undefined) {
      return this.updateResult()
    }

    info.items.splice(info.items.indexOf(itemToRemove), 1)
    info.itemMap[oddId] = undefined

    this.updateResult()

    this.oddSelectionChangeEvent.next({ info, calculate, betTypes, addModificationResult: false })
  }

  public async reuseBet(betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo

    if (info.isSubmitted()) {
      return this.updateResult()
    }

    info.items.forEach((item) => {
      if (item.automaticDisabled) {
        item.enabled = true
      }
      item.automaticDisabled = false
    })

    await this.createBets(undefined, betTypes)
    this.updateResult()
  }

  public async reuseBetslipItems(
    betItems: BetSlipServiceBetSlipItem[],
    betTypes: BetTypes = null,
    referenceId: string = null,
  ): Promise<void> {
    const info = this._betslipInfo

    info.items = []
    info.itemMap = []

    for (const item of betItems) {
      // if a bet on the same game exists in the betslip set it to MultiWay
      info.items.push(item)
      info.itemMap[item.odd.id] = item
    }

    await this.createBets(true, betTypes, referenceId)

    this.updateResult(true)
  }

  public async reuseBetslip(betSlip: BetSlip, betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo

    for (const i of betSlip.items) {
      const item = i as BetSlipServiceBetSlipItem
      item.enabled = true

      // if a bet on the same game exists in the betslip set it to MultiWay
      const previousItemWithSameGame = info.itemMap[item.oddId]

      if (previousItemWithSameGame != undefined) {
        previousItemWithSameGame.type = BetSlipItemType.MultiWay
        item.type = BetSlipItemType.MultiWay
      }

      info.items.push(item)
      info.itemMap[item.oddId] = item
    }

    await this.createBets(true, betTypes)
    this.updateResult(true)
  }

  public async markOddBanked(oddId: number, calculate: boolean = true, betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo

    if (info.isSubmitted()) {
      return this.updateResult()
    }

    const item = info.itemMap[oddId]

    const previousItemWithSameGame = info.items.find((i) => {
      return i.gameId == item.gameId && i.oddId != item.oddId
    })

    if (previousItemWithSameGame != null) {
      return this.updateResultError(item.gameId, item.oddId, ModifyBetSlipResultStatus.BankerAndMultiWayNotPossible)
    }

    item.type = BetSlipItemType.Banker
    await this.createBets(calculate, betTypes)
    this.updateResult(true)
  }

  public async unmarkOddBanked(oddId: number, calculate: boolean = true, betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo
    const item = info.itemMap[oddId]

    if (item == undefined || item.type != BetSlipItemType.Banker) {
      return this.updateResult()
    }

    item.type = BetSlipItemType.Regular
    await this.createBets(calculate, betTypes)
    return this.updateResult()
  }

  public async markOddEnabled(oddId: number, calculate: boolean = true, betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo

    if (info.isSubmitted()) {
      return this.updateResult()
    }

    const item = info.itemMap[oddId]
    if (item.enabled) {
      return this.updateResult()
    }

    item.enabled = true
    await this.createBets(calculate, betTypes)
    this.updateResult(true)
  }

  public async unmarkOddEnabled(oddId: number, calculate: boolean = true, betTypes: BetTypes = null): Promise<void> {
    const info = this._betslipInfo
    const item = info.itemMap[oddId]

    if (item == undefined || !item.enabled) {
      return this.updateResult()
    }

    item.enabled = false
    await this.createBets(calculate, betTypes)
    this.updateResult()
  }

  public async setAccountAndGivenTokenBonusId(
    account: Account,
    givenTokenBonusId: number,
    betTypes: BetTypes = null,
  ): Promise<void> {
    const info = this._betslipInfo

    if (account.id == info.accountId && givenTokenBonusId === this.givenTokenBonusId) {
      return this.updateResult()
    }

    if (info.isSubmitted()) {
      return this.updateResult()
    }

    this.givenTokenBonusId = givenTokenBonusId
    info.accountId = account.id
    info.currencyId = info.accounts.find((a) => a.id == info.accountId).currencyId || 0

    await this.createBets(true, betTypes)

    this.updateResult()
  }

  public async submitBet(request: BetSlipSubmitRequest): Promise<void> {
    const info = this._betslipInfo

    if (info.isSubmitted()) {
      if (request.acceptedChangedOdds != null && request.acceptedChangedOdds.length == 0) {
        return this.updateResult()
      }

      info.submitted = null
      info.submittedGuid = null
      info.waitSeconds = null
    }

    if (request.acceptedChangedOdds) {
      this.applyChangedOdds(request.acceptedChangedOdds)
    }

    const itemsToSubmit: BetValue[] = []

    info.createdBets
      ?.filter((bet) => request.betsToSubmit?.includes(bet.key.toString()))
      ?.forEach((p) => {
        p.value.checkBetResult = null
        p.value.placeBetResult = null
        p.value.state = BetSlipServiceStateBetState.Open

        itemsToSubmit.push(p.value)
      })

    try {
      const betSlipsToSubmit = itemsToSubmit.map((i) => i.betSlip)
      betSlipsToSubmit.forEach((bet) => (bet.stake = this.getBetStake(betSlipsToSubmit.length)))

      if (betSlipsToSubmit?.length == 0) {
        throw new Error()
      }

      if (betSlipsToSubmit?.length === 1 && this.givenTokenBonusId != null)
        betSlipsToSubmit[0].givenTokenBonusId = this.givenTokenBonusId

      const placeBetResult = await placeBets({ betSlips: betSlipsToSubmit, addToRecent: false })

      switch (placeBetResult.state) {
        case SubmitResultState.Error: {
          itemsToSubmit.forEach((item) => {
            item.state = BetSlipServiceStateBetState.Error
          })
          break
        }
        case SubmitResultState.Processed: {
          ;[].concat
            .apply(
              [],
              placeBetResult.results.filter((r) => r.checkResult != null).map((i) => i.checkResult.itemErrors),
            )
            .filter(
              (e) => e.Status == CheckBetSlipItemStatus.OddRaised || e.Status == CheckBetSlipItemStatus.OddLowered,
            )
            .map((e) => [e.oddId, e.oddValue])
            .forEach((t) => {
              info.itemMap[t[0]].oddValue = t[1]
              info.itemMap[t[0]].initialOddValue = t[1]
              info.itemMap[t[0]].odd.value = t[1]

              info.createdBets.forEach((cb) => {
                cb.value.betSlip?.items?.forEach((item) => {
                  if (item.oddId == t[0]) {
                    item.oddValue = t[1]
                    item.initialOddValue = t[1]
                  }
                })
              })
            })

          for (let i = 0; i < itemsToSubmit.length; i++) {
            itemsToSubmit[i].placeBetResult = placeBetResult.results[i] as PlaceBetSlipResult
            if (itemsToSubmit[i].placeBetResult.placeResultType == PlaceBetSlipResultType.Ok) {
              itemsToSubmit[i].state = BetSlipServiceStateBetState.Booked
            } else {
              itemsToSubmit[i].state = BetSlipServiceStateBetState.Error
            }
          }
          break
        }

        case SubmitResultState.Submitted: {
          info.submittedItems = itemsToSubmit
          info.submitted = new Date()
          info.submittedGuid = placeBetResult.guid
          info.waitSeconds = this.parseWaitSeconds(placeBetResult)
          break
        }
      }
    } catch {
      itemsToSubmit.forEach((item) => {
        item.state = BetSlipServiceStateBetState.Error
      })
    }
    this.updateResult()
  }

  public async shareBets(request: BetSlipShareRequest): Promise<string> {
    const info = this._betslipInfo

    const itemsToShare: BetValue[] = []

    info.createdBets
      ?.filter((bet) => request.betsToShare?.includes(bet.key.toString()))
      ?.forEach((p) => {
        itemsToShare.push(p.value)
      })

    try {
      const betSlipsToShare = itemsToShare.map((i) => i.betSlip)
      betSlipsToShare.forEach((bet) => (bet.stake = this.getBetStake(betSlipsToShare.length)))

      if (betSlipsToShare?.length == 0) {
        throw new Error()
      }

      if (betSlipsToShare?.length === 1 && this.givenTokenBonusId != null)
        betSlipsToShare[0].givenTokenBonusId = this.givenTokenBonusId

      const shareBetResult = await shareBet({ betSlips: betSlipsToShare, userPin: null })

      return shareBetResult.referenceId
    } catch {
      return ''
    }
  }

  public async setStake(
    request: BetSlipCalculateRequest,
    betTypes: BetTypes = null,
    options: CheckBetSlipOptions = null,
  ): Promise<void> {
    const info = this._betslipInfo
    this.stake = request.stake
    this.stakeMode = request.stakeMode

    this.applyChangedOdds(request.acceptedChangedOdds)

    await this.setStakes(info, true, betTypes, options ?? this.getDefaultCheckBetOptions())

    this.updateResult()
  }

  public async createBetsForBetType(betTypes: BetTypes = undefined) {
    await this.createBets(true, betTypes)
    this.updateResult()
  }

  public async acceptChangedOdds(
    changedOdds: BetServiceChangedOdd[],
    betTypes: BetTypes = null,
    options: CheckBetSlipOptions = null,
  ): Promise<void> {
    const info = this._betslipInfo

    if (info.isSubmitted()) {
      return this.updateResult()
    }

    info.submitted = null
    info.submittedGuid = null
    info.waitSeconds = null

    this.applyChangedOdds(changedOdds)
    this.removeUnaivalableItems()

    await this.setStakes(info, true, betTypes, options ?? this.getDefaultCheckBetOptions())

    this.updateResult()
  }

  public setGivenTokenBonusId(givenTokenBonusId: number) {
    this.givenTokenBonusId = givenTokenBonusId
  }

  public isBlockingItemError(error: CheckBetSlipItemError) {
    if (
      error != null &&
      (error.status === CheckBetSlipItemStatus.Ok ||
        error.status === CheckBetSlipItemStatus.OddLowered ||
        error.status === CheckBetSlipItemStatus.OddRaised)
    ) {
      return false
    }
    return true
  }

  private getDefaultCheckBetOptions() {
    return this._betslipInfo.userType == UserType.Anonymous ? checkOptionsAnonymous : checkOptionsLoggedOn
  }

  private getBetStake(betCount: number, betTypes: BetTypes = null): number {
    if (betTypes == null || betTypes.valueOf() & BetTypes.System) {
      return this.stake
    }

    return this.stakeMode === 'totalStake' ? this.stake / betCount : this.stake
  }

  private parseWaitSeconds(placeBetResult: SubmitBetSlipResult) {
    return parseInt(placeBetResult.waitTime.split(':')[2])
  }

  private updateResult(addModificationResult: boolean = false) {
    const info = this._betslipInfo
    let ws = null

    if (info == null) return

    if (info.submittedGuid != null) {
      const diff = info.waitSeconds - (Date.now() - info.submitted.getTime()) / 1000
      ws = diff > 0 ? diff : 0
    }

    let modifyBetSlipResult: ModifyBetSlipResult = null

    if (addModificationResult) {
      if (!info.minimumCombinationMet && info.items.length > 0) {
        modifyBetSlipResult = new ModifyBetSlipResult()
        modifyBetSlipResult.combination = info.minimumCombination
        modifyBetSlipResult.remaining = info.remaining
        modifyBetSlipResult.status = ModifyBetSlipResultStatus.MinimumCombiNotMet
      } else {
        modifyBetSlipResult = new ModifyBetSlipResult()
        modifyBetSlipResult.status = ModifyBetSlipResultStatus.Ok
      }
    }

    info.bets = this.createBetResults(info)
    info.canBank = this.canBank()
    info.modifyBetSlipResult = modifyBetSlipResult
    info.waitSeconds = ws

    this._betslip.next(this.betSlipInfoToResult())
  }

  private updateResultError(
    gameId: number,
    oddId: number,
    error: ModifyBetSlipResultStatus,
    testNewBetItemResult: TestNewBetSlipItemResult = null,
  ) {
    const info = this._betslipInfo
    info.canBank = this.canBank()
    info.bets = this.createBetResults(info)
    info.modifyBetSlipResult = this.createInitialCheckBetResult(error, gameId, oddId, testNewBetItemResult)

    this._betslip.next(this.betSlipInfoToResult())
  }

  private betSlipInfoToResult(): BetSlipServiceResult {
    const info = this._betslipInfo
    const res = new BetSlipServiceResult()
    res.items = [...info.items]
    res.bets = [...info.bets]
    res.modifyBetSlipResult = info.modifyBetSlipResult
    res.now = info.now

    if (info.submitted != null) {
      res.submitted = info.submitted
    }

    if (info.waitSeconds != null) {
      res.waitSeconds = info.waitSeconds
    }

    res.possibleSystemCombinations = info.possibleSystemCombinations
    res.canBank = info.canBank
    res.minimumCombination = info.minimumCombination
    res.betMode = info.betMode

    return res
  }

  private createBetResults(info: BetSlipInfo): BetSlipServiceStateBet[] {
    return info.createdBets.map((p) => {
      const bet = new BetSlipServiceStateBet()
      bet.identifier = p.key.toString()
      bet.state = p.value.state
      bet.checkBetResult = p.value.placeBetResult?.checkResult ?? p.value.checkBetResult
      bet.placeBetResult = p.value.placeBetResult
      bet.betSlip = p.value.betSlip
      return bet
    })
  }

  private canBank() {
    return true
  }

  private createInitialCheckBetResult(
    status: ModifyBetSlipResultStatus,
    gameId: number,
    oddId: number,
    testNewItemResult: TestNewBetSlipItemResult = null,
  ): ModifyBetSlipResult {
    const result = new ModifyBetSlipResult()
    result.status = status
    result.gameId = gameId
    result.oddId = oddId
    result.testNewItemResult = testNewItemResult
    return result
  }

  private createCopy(item: BetSlipItem): BetSlipItem {
    const copy = new BetSlipItem()
    copy.gameId = item.gameId
    copy.marketId = item.marketId
    copy.marketTypeId = item.marketTypeId
    copy.oddId = item.oddId
    copy.oddValue = item.oddValue
    copy.initialOddValue = item.initialOddValue
    copy.type = item.type
    copy.producer = item.producer
    copy.sportId = item.sportId
    copy.leagueId = item.leagueId
    return copy
  }

  private async testNewBetItem(item: BetSlipItem): Promise<TestNewBetSlipItemResult> {
    const info = this._betslipInfo

    const bs = new BetSlip()
    bs.state = BetSlipState.Open
    bs.userId = info.userId
    bs.accountId = info.accountId
    bs.branchId = info.branchId
    bs.currencyId = info.currencyId
    bs.source = info.source
    bs.items = []

    info.items.forEach((i) => {
      bs.items.push(this.createCopy(i))
    })

    bs.itemCount = bs.items.length

    return await testNewBetItem({ betSlip: bs, newItem: this.createCopy(item) })
  }

  private async createBets(
    calculate: boolean = true,
    betTypes: BetTypes = undefined,
    referenceId: string = undefined,
  ): Promise<void> {
    let bs = new BetSlip()
    const info = this._betslipInfo

    info.createdBets = []
    info.possibleSystemCombinations = undefined
    info.minimumCombination = 1
    info.minimumCombinationMet = false
    info.remaining = 0

    if (info.items.length < 1) {
      return
    }

    const enabledItems = info.items.filter((i) => i.enabled)
    const itemsWithoutSystems = enabledItems.filter((i) => enabledItems.find((it) => it.gameId == i.gameId) == i).length
    const bankers = enabledItems.filter((i) => i.type == BetSlipItemType.Banker).length

    let minCombi = 1
    let maxCombi = itemsWithoutSystems - bankers

    const allowSystem = true || !(this.branchBetSettings.options & BranchBetSettingOptions.ComplexBetsNotAllowed)

    this.prepareBetSlip(bs)
    enabledItems.forEach((i) => bs.items.push(this.createCopy(i)))
    bs.itemCount = bs.items.length
    let system = new BetSlipSystem()
    system.combination = 1
    if (bs.systems == null) {
      bs.systems = []
    }
    bs.systems.push(system)

    this._betCalculationInProgress.next(true)
    const result = await checkBet({
      betSlip: bs,
      options: CheckBetSlipOptions.Combinability,
      referenceId
    })
    this._betCalculationInProgress.next(false)

    if (result.checkResultType == CheckBetSlipResultType.Ok) {
      if (result.betSlip.minimumCombination != 0) {
        minCombi = result.betSlip.minimumCombination
      }
      if (result.betSlip.maximumCombination != 0) {
        maxCombi = result.betSlip.maximumCombination
      }
    }

    if (allowSystem) {
      info.possibleSystemCombinations = result.validCombinations
    } else {
      info.possibleSystemCombinations = [maxCombi]
    }

    info.minimumCombination = minCombi
    info.remaining = Math.max(minCombi - maxCombi, 0)
    info.minimumCombinationMet = minCombi <= maxCombi

    let bets = []
    // single bets
    if (minCombi < 2 && (betTypes == null || betTypes.valueOf() & BetTypes.Single)) {
      enabledItems.forEach((item) => {
        bs = new BetSlip()
        this.prepareBetSlip(bs)
        bs.items.push(this.createCopy(item))
        bs.itemCount = bs.items.length

        const system = new BetSlipSystem()
        system.combination = 1
        if (bs.systems == null) {
          bs.systems = []
        }
        bs.systems.push(system)

        const betKey = new BetKey()
        betKey.type = BetKeyType.Single
        betKey.value = item.oddId

        const betValue = new BetValue()
        betValue.betSlip = bs
        betValue.state = BetSlipServiceStateBetState.Open

        const bet = new BetSlipServiceBet()
        bet.key = betKey
        bet.value = betValue
        bets.push(bet)
      })
      info.createdBets = bets
    }

    let combiBetKey = new BetKey()
    if (info.minimumCombinationMet) {
      // System bets
      if (allowSystem && (betTypes == null || betTypes.valueOf() & BetTypes.System)) {
        for (let i = minCombi; i <= maxCombi; i++) {
          bs = new BetSlip()
          bs.state = BetSlipState.Open
          bs.userId = info.userId
          bs.accountId = info.accountId
          bs.branchId = info.branchId
          bs.currencyId = info.currencyId
          bs.source = info.source
          bs.items = []
          enabledItems.forEach((it) => {
            bs.items.push(this.createCopy(it))
          })
          bs.itemCount = maxCombi
          bs.isAnonymousBet = info.userType === UserType.PosAnonymous

          const sys = new BetSlipSystem()
          sys.combination = i
          if (bs.systems == null) {
            bs.systems = []
          }
          bs.systems.push(sys)

          const betKey = new BetKey()
          betKey.type = BetKeyType.System
          betKey.value = i

          const betValue = new BetValue()
          betValue.betSlip = bs
          betValue.state = BetSlipServiceStateBetState.Open

          const bet = new BetSlipServiceBet()
          bet.key = betKey
          bet.value = betValue
          bets.push(bet)
        }
        info.createdBets = bets
      }

      // Combi Bets
      if (betTypes == null || betTypes.valueOf() & BetTypes.Combi) {
        bs = new BetSlip()
        bs.state = BetSlipState.Open
        bs.userId = info.userId
        bs.accountId = info.accountId
        bs.branchId = info.branchId
        bs.currencyId = info.currencyId
        bs.source = info.source
        bs.items = []
        enabledItems.forEach((i) => {
          bs.items.push(this.createCopy(i))
        })
        bs.itemCount = maxCombi
        bs.isAnonymousBet = info.userType === UserType.PosAnonymous

        const sys = new BetSlipSystem()
        sys.combination = bs.itemCount
        if (bs.systems == null) {
          bs.systems = []
        }
        bs.systems.push(sys)

        combiBetKey = new BetKey()
        combiBetKey.type = BetKeyType.Combi
        combiBetKey.value = bs.itemCount

        const betValue = new BetValue()
        betValue.betSlip = bs
        betValue.state = BetSlipServiceStateBetState.Open

        const bet = new BetSlipServiceBet()
        bet.key = combiBetKey
        bet.value = betValue
        bets.push(bet)
      }
      info.createdBets = bets
    } // END if (MinimumCombiMet)

    const totalCreatedBets = info.createdBets?.length
    if (totalCreatedBets > 0) {
      info.createdBets.forEach((bet) => {
        bet.value.betSlip.stake = this.getBetStake(totalCreatedBets, betTypes)
      })
    }

    if (totalCreatedBets === 1 && this.givenTokenBonusId != null) {
      info.createdBets[0].value.betSlip.givenTokenBonusId = this.givenTokenBonusId
    }

    if (calculate) {
      await this.checkBets(
        info.createdBets.map((c) => c.value),
        this.getDefaultCheckBetOptions(),
        referenceId,
      )
    }
  }

  private async setStakes(
    info: BetSlipInfo,
    callCheckBet: boolean = true,
    betTypes: BetTypes = null,
    options: CheckBetSlipOptions = null,
  ) {
    await this.createBets(false, betTypes)

    for (const p of info.createdBets) {
      p.value.placeBetResult = null
    }

    if (callCheckBet) {
      await this.checkBets(
        info.createdBets.map((cb) => cb.value),
        options ?? this.getDefaultCheckBetOptions(),
      )
    }
  }

  private async checkBets(
    items: BetValue[],
    options: CheckBetSlipOptions = CheckBetSlipOptions.None,
    referenceId: string = undefined,
  ): Promise<void> {
    if (items.length < 1) {
      return
    }

    this._betCalculationInProgress.next(true)

    const betSlips = items.map((i) => i.betSlip)

    let result: CheckBetSlipResult[] | CheckBetResult[] = null

    if (this._betslipInfo.userType == UserType.Anonymous) {
      result = await checkBets({ betSlips, options, referenceId })
    } else {
      const checkBetsWithBonusesRequest: any = betSlips.map((b) => {
        const req = {
          ...b,
          options,
          isTokenBonusIncluded: true,
          tokenBonusCheckOptions:
            TokenBonusCheckOptions.AllChecks & ~TokenBonusCheckOptions.StakeEqualsFreeBetTokenAmount,
        }

        if (this.givenTokenBonusId != null) {
          req.givenTokenBonusId = this.givenTokenBonusId
        }

        return req
      })

      result = await checkBetsWithBonuses(checkBetsWithBonusesRequest)
    }

    this._betCalculationInProgress.next(false)

    for (let i = 0; i < result.length; i++) {
      items[i].checkBetResult = result[i]
      this.copyCalculatedValues(items[i])
    }
  }

  private copyCalculatedValues(cb: BetValue) {
    const checkResult = cb.checkBetResult
    const betSlip = cb.betSlip

    if (checkResult.betSlip != null) {
      betSlip.initialMaximumGain = checkResult.betSlip.initialMaximumGain
      betSlip.initialMaximumOdd = checkResult.betSlip.initialMaximumOdd
      betSlip.initialMaximumReturnedAmount = checkResult.betSlip.initialMaximumReturnedAmount
      betSlip.currentMaximumGain = checkResult.betSlip.currentMaximumGain
      betSlip.currentMaximumOdd = checkResult.betSlip.currentMaximumOdd
      betSlip.currentMaximumReturnedAmount = checkResult.betSlip.currentMaximumReturnedAmount
      betSlip['currentGainTax'] = checkResult.betSlip['currentGainTax']
      betSlip.bankerCount = checkResult.betSlip.bankerCount
      betSlip.betCount = checkResult.betSlip.betCount
      betSlip.stakeTax = checkResult.betSlip.stakeTax
      betSlip.stakeFee = checkResult.betSlip.stakeFee
      betSlip.stakeBonus = checkResult.betSlip.stakeBonus
      betSlip.gainBonus = checkResult.betSlip.currentGainBonus
      betSlip.stake = checkResult.betSlip.stake
      betSlip.chargedAmount = checkResult.betSlip.chargedAmount
    }

    checkResult.betSlip = null
    cb.checkBetResult.betSlip = null
  }

  private clearBetInt(info: BetSlipInfo): void {
    if (info == null) return

    info.items = []
    info.itemMap = []
    info.bets = []
    info.createdBets = []
    info.submitted = null
    info.submittedGuid = null
    info.waitSeconds = null
    info.submittedItems = null
    info.possibleSystemCombinations = null
    info.minimumCombination = 1
    info.remaining = 0
    info.minimumCombinationMet = false
  }

  private removeUnaivalableItems() {
    const info = this._betslipInfo

    for (const bet of info.createdBets) {
      const itemErrors = bet.value?.checkBetResult?.itemErrors ?? []

      for (const itemError of itemErrors) {
        const isOddUnavailable = this.isBlockingItemError(itemError)

        if (isOddUnavailable) {
          info.itemMap[itemError.oddId] = undefined
          info.items = info.items?.filter((item) => item.oddId !== itemError.oddId)
          bet.value.betSlip.items = bet.value.betSlip.items.filter((item) => item.oddId !== itemError.oddId)
        }
      }
    }
  }

  private applyChangedOdds(changedOdds: BetServiceChangedOdd[]) {
    const info = this._betslipInfo

    for (const odd of changedOdds) {
      const betSlipItem: BetSlipServiceBetSlipItem = info.itemMap[odd.oddId]

      if (betSlipItem == null || odd.newValue <= 0) {
        continue
      }

      betSlipItem.oddValue = odd.newValue
      betSlipItem.initialOddValue = odd.newValue
      betSlipItem.odd.value = odd.newValue
    }

    for (const bet of info.createdBets) {
      for (const odd of changedOdds) {
        const betSlipItem = bet.value.betSlip.items.find((i) => i.oddId == odd.oddId)

        if (betSlipItem == null || odd.newValue <= 0) {
          continue
        }

        betSlipItem.oddValue = odd.newValue
        betSlipItem.initialOddValue = odd.newValue
      }
    }
  }

  private prepareBetSlip(bs: BetSlip) {
    const info = this._betslipInfo
    bs.state = BetSlipState.Unknown
    bs.userId = info.userId
    bs.accountId = info.accountId
    bs.branchId = info.branchId
    bs.currencyId = info.currencyId
    bs.source = info.source
    bs.items = []
  }
}
